import styled from "styled-components";
import { colors } from "../../../theme";

export const Wrapper = styled.div`
  position: relative;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  padding: 70px 0 80px 0;
`

export const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Content = styled.div`
  max-width: 450px;
  margin: 0 auto;
  padding: 0 30px 0 45px;
`

export const Date = styled.div`
  font-family: 'Dancing Script', cursive;
  font-weight: 500;
  color: white;
  font-size: 34px;
  line-height: 34px;
  margin-bottom: 20px;
  ::first-letter {
    text-transform: capitalize;
  }
`

export const Message = styled.div`
  position: relative;
  border-radius: 10px;
  color: #323232;
  font-family: 'Libre Baskerville', serif;
  font-size: 15px;
  background-color: #fff;
  padding-bottom: 8px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
  z-index: 0;
  > div:first-child {
    z-index: 0;
    ::after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent #fff transparent transparent;
  }
  ::before {
    position: absolute;
    content: '';
    top: 3px;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent rgba(0, 0, 0, 0.3) transparent transparent;
    z-index: -2;
  }
  }

  > div:nth-child(2) {
    background-color: white;
    border-radius: 10px;
    padding: 20px 20px;
    z-index: 1;
    color: #323232;
  }

  > div:last-child {
    text-align: right;
    color: #717171;
    margin-right: 15px;
  }
`
