import React from 'react'
import ReactMardown from 'react-markdown'
import MessageduSoirState from './index.state'
import { Loader100vh } from '../../UI/Loader'
import { FormattedDate } from 'react-intl'
import { Back, Wrapper, Content, Date, Message } from './index.styles'
import { Previous } from 'grommet-icons'
import { Link } from 'gatsby'

const MessageDuSoir = () => {
  const { loading, message } = MessageduSoirState.useContainer()

  if (loading) return <Loader100vh />
  return <Wrapper>
    <Back>
      <Link to='/messages'><Previous color='#fff' size='30px' /></Link>
    </Back>
    <Content>
      <Date><FormattedDate value={(message.date.seconds * 1000)} weekday="long" month='long' day='numeric' year='numeric' /></Date>
      <Message>
        <div />
        <div>
          <ReactMardown source={message.content} />
        </div>
        <div><FormattedDate value={(message.date.seconds * 1000)} /></div>
      </Message>
    </Content>
  </Wrapper>
}

MessageDuSoir.propTypes = {

}

MessageDuSoir.defaultProps = {

}

export default ({ number }) => <MessageduSoirState.Provider initialState={{ number }}>
  <MessageDuSoir />
</MessageduSoirState.Provider>
