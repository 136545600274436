import { createContainer } from 'unstated-next'
import { useState, useEffect } from "react"
import FirebaseState from '../../../state/FirebaseState'

const useMds = ({ number }) => {
  const { firebase } = FirebaseState.useContainer()
  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    message.url && getData(message.url)
  }, [message])

  const getData = (url) => {
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      setMessage({ ...message, content: xhr.responseText })
      setLoading(false)
    })
    xhr.open('GET', url)
    xhr.send()
  }

  useEffect(() => {
    firebase
    .firestore()
    .collection("mds")
    .doc(number).get().then((doc) => {
      setMessage(doc.data())
    })
  }, [])

  return { loading, message }
}

const MessageDuSoirState = createContainer(useMds)

export default MessageDuSoirState