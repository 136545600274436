import React from 'react'
import PrivateLayout from '../layouts/private'
import MessageDuSoir from '../components/pages/MessageDuSoir'

const MessageDuSoirPage = ({ number }) => {
  return <PrivateLayout><MessageDuSoir number={number} /></PrivateLayout>
}

MessageDuSoirPage.propTypes = {

}

MessageDuSoirPage.defaultProps = {

}

export default MessageDuSoirPage
